import {authRoles} from "../../auth/authRoles";
import { MatxLoadable } from "matx";

const LiveRoom = MatxLoadable({
    loader: () => import("./LiveRoom")
});

const NewRoom = MatxLoadable({
    loader: () => import("./newRoom")
});

const Absents = MatxLoadable({
    loader: () => import("./LiveRoomAbsents")
});

const LiveRoomRoutes = [
    {
        exact: true,
        path: `/liveRoom`,
        component: LiveRoom,
        auth: authRoles.adminTwoFour
    },
    {
        path: '/liveRoom/newRoom',
        component: NewRoom,
        auth: authRoles.adminTwoFour
    },{
        exact: true,
        path: `/liveRoom/AbsentList/:id`,
        component: Absents,
        auth: authRoles.adminTwoFour
    },
];

export default LiveRoomRoutes;
