import { MatxLoadable } from "matx";
import { authRoles } from "../../auth/authRoles";

const Admins = MatxLoadable({
    loader: () => import("./Admins")
})

const AddAdmins = MatxLoadable({
    loader: () => import("./details/AddAdmin")
})

const adminsRoute = [
    {
        exact: true,
        path: `/admins`,
        component: Admins,
        auth: authRoles.admin
    },{
        path: `/admins/AddNew`,
        component: AddAdmins,
        auth: authRoles.admin
    },
];

export default adminsRoute;