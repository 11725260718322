import {authRoles} from "../../auth/authRoles";
import { MatxLoadable } from "matx";

const Lesson = MatxLoadable({
    loader: () => import("./Lesson")
});
const LessonTags = MatxLoadable({
    loader: () => import("./LessonTags")
});
const Term = MatxLoadable({
    loader: () => import("./Terms")
});
const Tags = MatxLoadable({
    loader: () => import("./Tags")
});
const TagFiles = MatxLoadable({
    loader: () => import("./TagFiles")
});
const TermFiles = MatxLoadable({
    loader: () => import("./TermFiles")
});
const Students = MatxLoadable({
    loader: () => import("./userList")
});
const ShowVideo = MatxLoadable({
    loader: () => import("./ShowVideo")
});
const AbsentList = MatxLoadable({
    loader: () => import("./AbsentList")
})
const AbsentUsers = MatxLoadable({
    loader: () => import("./AbsentUsers")
})

const CoursesRoutes = [
    {
        path: `/lessons`,
        component: Lesson,
        auth: authRoles.adminTwo
    },{
        path: `/lessonTags/:id`,
        component: LessonTags,
        auth: authRoles.adminTwo
    },{
        exact: true,
        path: `/terms`,
        component: Term,
        auth: authRoles.all
    },{
        exact: true,
        path: `/terms/tags/:id`,
        component: Tags,
        auth: authRoles.all
    },{
        exact: true,
        path: `/terms/tags/files/:id`,
        component: TagFiles,
        auth: authRoles.all
    },{
        path: `/terms/files/List/:id`,
        component: TermFiles,
        auth: authRoles.all
    },{
        exact: true,
        path: `/terms/userList/:id`,
        component: Students,
        auth: authRoles.admin
    },{
        path: `/terms/tags/files/:prev/view/:id`,
        component: ShowVideo,
        auth: authRoles.all
    },{
        exact: true,
        path: '/terms/userList/AbsentList/:id',
        component: AbsentList,
        auth: authRoles.all
    },{
        exact: true,
        path: '/terms/userList/AbsentList/users/:id',
        component: AbsentUsers,
        auth: authRoles.all
    }
];

export default CoursesRoutes;
