import { MatxLoadable } from "matx";
import { authRoles } from "../../auth/authRoles";

const Offs = MatxLoadable({
  loader: () => import("./Offs")
})

const OffEdit = MatxLoadable({
  loader: () => import("./details/OffEdit")
})

const offRoutes = [
  {
    exact: true,
    path: `/OFF`,
    component: Offs,
    auth: authRoles.adminTwo
  },
  {
    path: `/OFF/AddNew`,
    component: OffEdit,
    auth: authRoles.adminTwo
  },
  {
    path: `/OFF/edit/:id`,
    component: OffEdit,
    auth: authRoles.adminTwo
  },
];

export default offRoutes;
