import React, { Component } from "react";
import {
    Card,
    Grid,
    Button,
    withStyles,
    CircularProgress
} from "@material-ui/core";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { withRouter } from "react-router-dom";

import {newPassword} from "../../../redux/actions/LoginActions";

class ForgotPassword2 extends Component {
    state = {
        password: "",
        repeatPassword: ""
    };
    componentDidMount() {
        ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
            if (value !== this.state.password) {
                return false;
            }
            return true;
        });
    }
    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };
    handleFormSubmit = () => {
        this.props.newPassword(this.state.password, this.props.login.code, this.props.login.phoneNumber, this.props.history);
    };
    render() {
        let { password, repeatPassword } = this.state;

        return (
            <Card className="signup-card position-relative y-center">
                <Grid container>
                    <Grid item lg={5} md={5} sm={5} xs={12}>
                        <div className="p-32 flex flex-center flex-middle h-100">
                            <img src="/assets/images/logo.png" alt="" />
                        </div>
                    </Grid>
                    <Grid item lg={7} md={7} sm={7} xs={12}>
                        <div className="p-36 h-100 bg-light-gray position-relative">
                            <ValidatorForm ref="form" onSubmit={this.handleFormSubmit}>
                                <TextValidator
                                    className="mb-16 w-100"
                                    label="رمز عبور جدید"
                                    variant="outlined"
                                    onChange={this.handleChange}
                                    name="password"
                                    type="password"
                                    value={password}
                                    validators={["required"]}
                                    errorMessages={["این فیلد اجباریست"]}
                                />
                                <TextValidator
                                    className="mb-16 w-100"
                                    label="تکرار رمز عبور جدید"
                                    variant="outlined"
                                    onChange={this.handleChange}
                                    name="repeatPassword"
                                    type="password"
                                    value={repeatPassword}
                                    validators={["isPasswordMatch", "required"]}
                                    errorMessages={["پسوردها یکسان نیست","این فیلد اجباریست"]}
                                />
                                <div className="flex flex-middle">
                                    <Button variant="contained" color="primary" type="submit">
                                        بازیابی رمز عبور
                                    </Button>
                                </div>
                            </ValidatorForm>
                        </div>
                    </Grid>
                </Grid>
            </Card>
        );
    }
}

const mapStateToProps = state => ({
    resetPassword: PropTypes.func.isRequired,
    login: state.login
});
export default withRouter(
    connect(
        mapStateToProps,
        { newPassword }
    )(ForgotPassword2)
);
