import { MatxLoadable } from "matx";
import { authRoles } from "../../auth/authRoles";

const News = MatxLoadable({
  loader: () => import("./News")
})

const NewsEdit = MatxLoadable({
  loader: () => import("./details/NewsEdit")
})

const newsRoutes = [
  {
    exact: true,
    path: `/news`,
    component: News,
    auth: authRoles.adminTwo
  },
  {
    path: `/news/edit/:id`,
    component: NewsEdit,
    auth: authRoles.adminTwo
  },
  {
    path: `/news/AddNew`,
    component: NewsEdit,
    auth: authRoles.adminTwo
  },
];

export default newsRoutes;
