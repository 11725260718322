import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Icon } from "@material-ui/core";
import TouchRipple from "@material-ui/core/ButtonBase";
import MatxVerticalNavExpansionPanel from "./MatxVerticalNavExpansionPanel";
import { withStyles } from "@material-ui/styles";
import localStorageService from "../../../app/services/localStorageService";
import {connect} from "react-redux";
import {dimLoading, startSnack} from "../../../app/redux/actions/WidgetActions";

const styles = theme => ({
  expandIcon: {
    transition: "transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms",
    transform: "rotate(90deg)"
  },
  collapseIcon: {
    transition: "transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms",
    transform: "rotate(0deg)"
  }
});

class MatxVerticalNav extends Component {
  state = {
    collapsed: true
  };

  renderLevels = data => {
    return data.map((item, index) => {
      // console.log(item);
      if (item.children) {
        if(localStorageService.getItem("auth_admin")){
          if(item.auth.includes(localStorageService.getItem("auth_admin").role)) {
            return (
                <MatxVerticalNavExpansionPanel item={item} key={index}>
                  {this.renderLevels(item.children)}
                </MatxVerticalNavExpansionPanel>
            );
        }
        }} else {
        if(localStorageService.getItem("auth_admin")){
          if(item.auth.includes(localStorageService.getItem("auth_admin").role)) {
            return (
                <NavLink key={index} to={item.path} className="nav-item">
                  <TouchRipple key={item.name} name="child" className="w-100">
                    {(() => {
                      if (item.icon) {
                        return (
                            <Icon className="item-icon text-middle ml-10">{item.icon}</Icon>
                        );
                      } else {
                        return (
                            <span className="item-icon icon-text">{item.iconText}</span>
                        );
                      }
                    })()}
                    <span className="text-middle pl-20 item-text">{item.name}</span>
                    <div className="mx-auto"></div>
                    {item.badge && (
                        <div className={`badge bg-${item.badge.color}`}>
                          {item.badge.value}
                        </div>
                    )}
                  </TouchRipple>
                </NavLink>
            );
          }}}
    });
  };

  handleClick = () => {
    this.setState({ collapsed: !this.state.collapsed });
  };

  render() {
    return (
      <div className="navigation">
        {this.renderLevels(this.props.navigation)}
      </div>
    );
  }
}

const mapStateToProps = state =>({
  data: state.data.retrieve
});

export default connect(mapStateToProps, {dimLoading, startSnack})(withStyles(styles)(MatxVerticalNav));
