import React, {Component} from "react";

function PersianNumber(props){
    let en_number = props.number.toString();
    let persianDigits = "۰۱۲۳۴۵۶۷۸۹";
    let persianMap = persianDigits.split("");
    let persian_number = en_number.replace(/\d/g, function (m) {
        return persianMap[parseInt(m)];
    });
    if(props.spacing){
        let stringNum = persian_number.toString();
        var outPut='';
        let j=1;
        let k=stringNum.length;
        for (let i = stringNum.length-1; i >=0 ; i--) {
            if(j%3 === 0){
                if (outPut === '') {
                    outPut = stringNum.slice(k - 3, k);
                } else {
                    outPut = stringNum.slice(k - 3, k) + ',' + outPut;
                }
                k=k-3;
            }
            j++;
        }
        if(k !== 0){
            if (outPut === '') {
                outPut = stringNum.slice(0, k);
            } else {
                outPut = stringNum.slice(0, k) + ',' + outPut;
            }
        }
    }

    return (
        <span className={props.className} >{(props.spacing)?outPut:persian_number}</span>
    )
}
export default PersianNumber