import React, { Component } from "react";
import { Button } from "@material-ui/core";

class NotFound extends Component {
  state = {};
  render() {
    return (
      <div className="flex flex-center flex-middle w-100 h-100vh">
        <div className="flex flex-column flex-center flex-middle" style={{ maxWidth: "320px" }}>
          <img className="mb-32 border-radius-8" style={{maxWidth:'240%'}} src="/assets/images/404.png" alt="" />
          <Button
            className="capitalize"
            variant="contained"
            color="primary"
            onClick={() => window.history.go(-1)}
          >
            بازگشت به صفحه قبل
          </Button>
        </div>
      </div>
    );
  }
}

export default NotFound;
