import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { setUserData } from "../redux/actions/UserActions";
import jwtAuthService from "../services/jwtAuthService";
import localStorageService from "../services/localStorageService";
import firebaseAuthService from "../services/firebase/firebaseAuthService";
import history from "history.js";

class Auth extends Component {
  state = {
    run: false
  };

  constructor(props) {
    super(props);

    // Set user if exists in local storage
    // This is only for demo purpose
    // You should remove this
    this.props.setUserData(localStorageService.getItem("auth_admin"));
    // Check current token is valid on page load/reload
    let self = this;
    if(localStorageService.getItem("auth_admin")) {
      this.checkJwtAuth().then(async function (result) {
        self.setState({run: true})
      })
    }else{
      this.state={
        run: true
      }
    }
    // this.checkFirebaseAuth();
  }

  checkJwtAuth = async () => {
      await jwtAuthService
        .loginWithToken()
            .then(user => {
      if(localStorageService.getItem("auth_admin")) {
        console.log(localStorageService.getItem("auth_admin"));
        this.props.setUserData(localStorageService.getItem("auth_admin"));
      }else{
        history.push({
          pathname: `/session/signin`
        });
      }
    }).catch(err => {
      history.push({
        pathname: `/session/signin`
      });
    });
  };

  checkFirebaseAuth = () => {
    firebaseAuthService.checkAuthStatus(user => {
      if (user) {
        console.log(user.uid);
        console.log(user.email);
        console.log(user.emailVerified);
      } else {
        console.log("not logged in");
      }
    });
  };

  render() {
    const { children } = this.props;
    if (this.state.run) {
      return <Fragment>{children}</Fragment>;
    }else{
      return null
    }
  }
}

const mapStateToProps = state => ({
  setUserData: PropTypes.func.isRequired,
  login: state.login
});

export default connect(
  mapStateToProps,
  { setUserData }
)(Auth);
