import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ForgotPassword1 from "./forgot/ForgotPassword1";
import ForgotPassword2 from "./forgot/ForgotPassword2";
import ForgotPassword3 from "./forgot/ForgotPassword3";


class ForgotPassword extends Component {
    state = {

    };

    render() {

        return (
            <div className="signup flex flex-center w-100 h-100vh">
                <div className="p-8">
                    {this.props.login === 1 &&
                        <ForgotPassword1/>
                    }
                    {this.props.login === 2 &&
                        <ForgotPassword2/>
                    }
                    {this.props.login === 3 &&
                        <ForgotPassword3 history={this.props.history}/>
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    login: state.login.forgetPage
});
export default withRouter(
    connect(
        mapStateToProps,
        {}
    )(ForgotPassword)
);
