import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {NavLink} from "react-router-dom";
import PersianNumber from "../../../libs/PersianNumber";
import {
    Chip,
    Typography,
    CardContent,
    CardMedia,
    Card,
    CardActions,
    Button,
    CardActionArea,
    Icon, IconButton, Tooltip, Divider
} from "@material-ui/core";
import {connect} from "react-redux";
import {dimLoading} from "../../../app/redux/actions/WidgetActions";
import {Post} from "../../../libs/DataManager";
import {addPost} from "../../../app/redux/actions/DataActions";
import localStorageService from "../../../app/services/localStorageService";

const useStyles = makeStyles({
    root: {
        boxShadow:' 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
    },
    media:{
        cursor:'help'
    },
    price:{
        marginRight:'5px',
        marginLeft: '5px',
        padding: '5px',
        borderRadius: '4px',
        textAlign:'center',
        border: '1px solid #ff9e43',
        fontSize: '12px'
    },
});

const ShowCard = (props) => {

    function BuyBasket(id, history) {
        if (localStorageService.getItem('auth_user')) {
            props.dimLoading();
            Post('/api/user/payment/cart', null, {id: id}, props, {
                success: 'با موفقیت به سبد خرید اضافه شد.',
                error: 'مشکلی رخ داده است.'
            })
        }else{
            history.push('/session/signin');
        }
    }

    const {data,  button, history} = props;
    const {pathname} = history.location;
    const classes = useStyles();
    return (
        <Card className={classes.root}>
            <Tooltip title={data.description} placement='top' disableFocusListener arrow >
                <CardActionArea className={classes.media} >
                    {data.offPrice !== -1 &&
                        <div className='pos-absolute' style={{right: 10, top: 10}}>
                            <Chip
                                label={<PersianNumber number={' تخفیف خورده: '+data.offPrice+' تومان'} />}
                                color="primary"
                                icon={<Icon>local_offer</Icon>}
                            />
                    </div>
                    }
                    <CardMedia
                        component="img"
                        alt="Contemplative Reptile"
                        height="250"
                        image={data.image}
                        // title={data.description}
                    />
                    <CardContent>
                        <div className='flex--row align-items--center mt--4 justifyCbetween'>
                            <Typography gutterBottom variant="h6" component="h2">
                                <b>
                                    {data.name}
                                </b>
                            </Typography>
                            {data.price != undefined &&
                                <Typography  className={`${classes.price} ${data.offPrice !== -1 ? 'text-lined' : null }`} variant="body2" component="p">
                                    <PersianNumber spacing={true} className={`${data.offPrice !== -1 ? 'text-lined' : null}`} number={data.price}/> تومان
                                </Typography>
                            }
                        </div>
                        <Divider className='mt-8' />
                        <Typography noWrap className='mt-12' variant="body2" color="textSecondary" component="p">
                            {data.description}
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Tooltip>
            <CardActions className='justifyCbetween'>
                <NavLink  to={`${pathname}/${data.id}`} className="nav-item">
                    <Button variant='outlined' size="small" color="primary">
                        {button}
                    </Button>
                </NavLink>
                {props.data.buy &&
                <Tooltip title="اضافه به سبد خرید" aria-label="add">
                    <IconButton
                        disabled={data.isBought}
                        color='secondary'
                        style={{paddingTop: 10}}
                        onClick={() => {
                            BuyBasket(props.data.id, props.history);
                        }}
                    >
                        <Icon>
                            add_shopping_cart_icon
                        </Icon>
                    </IconButton>
                </Tooltip>
                }
            </CardActions>
        </Card>
    );
};

export default connect(null, {dimLoading, addPost})(ShowCard);