import React from "react";
import { Redirect } from "react-router-dom";

import dashboardRoutes from "./views/dashboard/DashboardRoutes";
import utilitiesRoutes from "./views/utilities/UtilitiesRoutes";
import sessionRoutes from "./views/sessions/SessionRoutes";
import userRoutes from "./views/users/UserRoutes";
import teacherRoutes from "./views/teachers/TeacherRoutes";
import offRoutes from './views/offs/OffRoutes'
import newsRoutes from './views/news/NewsRoutes'
import messagesRoute from './views/messages/MessagesRoute'
import materialRoutes from "./views/material-kit/MaterialRoutes";
import dragAndDropRoute from "./views/Drag&Drop/DragAndDropRoute";

import formsRoutes from "./views/forms/FormsRoutes";
import mapRoutes from "./views/map/MapRoutes";
import CoursesRoutes from "./views/courses/CoursesRoutes";
import AddNewRoutes from "./views/courses/AddEdit/AddNewRoutes";
import LiveRoomRoutes from "./views/liveRoom/LiveRoomRoutes";
import PaymentsRoutes from "./views/payments/PaymentsRoutes";
import adminsRoute from "./views/admins/AdminsRoute";
import PackagesRoutes from "./views/packages/PackagesRoutes";
import LiveRoomTestRoutes from "./views/liveRoomTest/LiveRoomTestRoutes";

export const BaseAdminUrl = '/admin';

const redirectRoute = [
  {
    path: `/` ,
    exact: true,
    component: () => <Redirect to={`/session/signin`} />
  }
];

const errorRoute = [
  {
    component: () => <Redirect to={`/session/404`} />
  }
];

const routes = [
  ...messagesRoute,
  ...newsRoutes,
  ...offRoutes,
  ...teacherRoutes,
  ...userRoutes,
  ...sessionRoutes,
  ...dashboardRoutes,
  ...materialRoutes,
  ...utilitiesRoutes,
  ...dragAndDropRoute,
  ...formsRoutes,
  ...mapRoutes,
  ...AddNewRoutes,
  ...CoursesRoutes,
  ...LiveRoomRoutes,
  ...LiveRoomTestRoutes,
  ...PaymentsRoutes,
  ...adminsRoute,
  ...PackagesRoutes,
  ...redirectRoute,
  ...errorRoute
];

export default routes;
