import React, { Component } from "react";
import {
  Card,
  Grid,
  Button,
  withStyles,
  CircularProgress
} from "@material-ui/core";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { withRouter } from "react-router-dom";

import { resetPassword } from "../../../redux/actions/LoginActions";
import {NumberInput} from "react-hichestan-numberinput";

class ForgotPassword1 extends Component {
  state = {
    mobile: ""
  };
  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };
  handleFormSubmit = () => {
    this.props.resetPassword({ ...this.state });
  };
  render() {
    let { mobile } = this.state;

    return (
        <Card className="signup-card position-relative y-center">
          <Grid container>
            <Grid item lg={5} md={5} sm={5} xs={12}>
              <div className="p-32 flex flex-center flex-middle h-100">
                <img src="/assets/images/logo.png" alt="" />
              </div>
            </Grid>
            <Grid item lg={7} md={7} sm={7} xs={12}>
              <div className="p-36 h-100 bg-light-gray position-relative">
                <ValidatorForm ref="form" onSubmit={this.handleFormSubmit}>
                  <TextValidator
                      className="mb-24 w-100"
                      variant="outlined"
                      label="شماره موبایل"
                      onChange={this.handleChange}
                      type="text"
                      name="mobile"
                      value={mobile}
                      InputProps={{
                        inputComponent: NumberInput,
                        inputProps: {maxLength: 12},
                      }}
                      validators={["required"
                      ]}
                      errorMessages={[
                        "این فیلد اجباریست",
                      ]}
                  />
                  <div className="flex flex-middle">
                    <Button variant="contained" color="primary" type="submit">
                      ارسال کد به تلفن همراه
                    </Button>
                    <span className="ml-16 mr-8">یا</span>
                    <Button
                        className="capitalize"
                        onClick={() =>
                            this.props.history.push("/session/signin")
                        }
                    >
                      وارد شوید
                    </Button>
                  </div>
                </ValidatorForm>
              </div>
            </Grid>
          </Grid>
        </Card>
    );
  }
}

const mapStateToProps = state => ({
  resetPassword: PropTypes.func.isRequired,
  login: state.login
});
export default withRouter(
  connect(
    mapStateToProps,
    { resetPassword }
  )(ForgotPassword1)
);
