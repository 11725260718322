import { MatxLoadable } from "matx";
import { authRoles } from "../../auth/authRoles";

const Messages = MatxLoadable({
  loader: () => import("./Messages")
})

const MessagesEdit = MatxLoadable({
  loader: () => import("./details/MessagesEdit")
})

const Viewers = MatxLoadable({
  loader: () => import("./details/Viewers")
})

const NotifViewers = MatxLoadable({
  loader: () => import("./details/NotifViewers")
})

const messagesRoute = [
  {
    exact: true,
    path: `/messages`,
    component: Messages,
    auth: authRoles.adminTwo
  },
  {
    path: `/messages/viewers/:id`,
    component: Viewers,
    auth: authRoles.adminTwo
  },
  {
    path: `/notifications/viewers/:id`,
    component: NotifViewers,
    auth: authRoles.adminTwo
  },
  {
    path: `/messages/AddNew`,
    component: MessagesEdit,
    auth: authRoles.adminTwo
  },
];

export default messagesRoute;
