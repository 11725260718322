import { MatxLoadable } from "matx";
import { authRoles } from "../../auth/authRoles";

const Payments = MatxLoadable({
    loader: () => import("./Payments")
});

const Terms = MatxLoadable({
    loader: () => import("./Terms")
});

const Stats = MatxLoadable({
    loader: () => import("./Stats")
})

const PaymentsRoutes = [
    {
        exact: true,
        path: `/payments`,
        component: Payments,
        auth: authRoles.admin
    },
    {
        path: '/payments/terms/:id',
        component: Terms,
        auth: authRoles.admin
    },
    {
        path: '/payments/stats',
        component: Stats,
        auth: authRoles.admin
    }
];

export default PaymentsRoutes;
