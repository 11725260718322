import { MatxLoadable } from "matx";
import { authRoles } from "../../auth/authRoles";

const Packages = MatxLoadable({
    loader: () => import("./Packages")
})
const AddPackage = MatxLoadable({
    loader: () => import("./Details/Packages")
})
const PackageLessons = MatxLoadable({
    loader: () => import("./Details/Lessons")
})

const PackagesRoutes = [
    {
        exact: true,
        path: `/Packages`,
        component: Packages,
        auth: authRoles.adminTwo
    },{
        path: '/Packages/AddNew',
        component: AddPackage,
        auth: authRoles.adminTwo
    },{
        path: '/Packages/edit/:id',
        component: AddPackage,
        auth: authRoles.adminTwo
    },{
        path: '/Packages/Lessons/:id',
        component: PackageLessons,
        auth: authRoles.adminTwo
    }
];

export default PackagesRoutes;
