import { MatxLoadable } from "matx";
import { authRoles } from "../../auth/authRoles";

const Users = MatxLoadable({
  loader: () => import("./Users")
})

const UserEdit = MatxLoadable({
  loader: () => import("./UserEdit")
})

const ProfileEdit = MatxLoadable({
  loader: () => import("./details/ProfileEdit")
})

const userRoutes = [
  {
    exact: true,
    path: `/users`,
    component: Users,
    auth: authRoles.noMali
  },
  {
    exact: true,
    path: `/users/edit/:id`,
    component: UserEdit,
    auth: authRoles.noMali
  },
  {
    path: `/users/edit/:id/editProfile`,
    component: ProfileEdit,
    auth: authRoles.noMali
  }
];

export default userRoutes;
