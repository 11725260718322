import "../fake-db";
import "../styles/_app.scss";
import React from "react";
import { Provider } from "react-redux";
import {Router} from "react-router-dom";
import MatxTheme from "./MatxLayout/MatxTheme/MatxTheme";
import AppContext from "./appContext";
import history from "history.js";
import {StylesProvider, jssPreset, ThemeProvider, createMuiTheme} from '@material-ui/core/styles';
import routes from "./RootRoutes";
import { Store } from "./redux/Store";
import Auth from "./auth/Auth";
import MatxLayout from "./MatxLayout/MatxLayout";
import AuthGuard from "./auth/AuthGuard";
import { create } from 'jss';
import rtl from 'jss-rtl';
import jMoment from "moment-jalaali";
import { faIR } from '@material-ui/core/locale';

jMoment.loadPersian({ dialect: "persian-modern", usePersianDigits: true });

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const theme = createMuiTheme({

}, faIR);

const App = () => {
  return (
    <AppContext.Provider value={{ routes }}>
      <Provider store={Store}>
        <ThemeProvider theme={theme}>
          <StylesProvider jss={jss}>
            <MatxTheme>
              <Auth>
                <Router history={history}>
                  <AuthGuard>
                    <MatxLayout/>
                  </AuthGuard>
                </Router>
              </Auth>
            </MatxTheme>
          </StylesProvider>
        </ThemeProvider>
      </Provider>
    </AppContext.Provider>
  );
};

export default App;
