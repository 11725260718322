import { MatxLoadable } from "matx";
import { authRoles } from "../../auth/authRoles";

const Teachers = MatxLoadable({
  loader: () => import("./Teachers")
})

const TeacherDetails = MatxLoadable({
  loader: () => import("./TeacherDetails")
})

const TeacherTerms = MatxLoadable({
  loader: () => import("./Terms")
})

const TeacherExams= MatxLoadable({
  loader: ()=> import("./Exams")
})

const TeacherNewExams= MatxLoadable({
  loader: ()=> import("./TeacherTermsDetails/NewExam")
})

const TeacherForum = MatxLoadable({
  loader: () => import("./TeacherTermsDetails/Forum")
})

const TeacherExercises = MatxLoadable({
  loader: () => import("./TeacherTermsDetails/Exercises")
})

const ExercisesEdit= MatxLoadable({
  loader: () => import("./TeacherTermsDetails/details/ExercisesEdit")
})

const UserList= MatxLoadable({
  loader: () => import("./TeacherTermsDetails/details/UserList")
})

const teacherRoutes = [
  {
    exact: true,
    path: `/teachers`,
    component: Teachers,
    auth: authRoles.noMali
  },
  {
    path: `/teachers/edit/:id`,
    component: TeacherDetails,
    auth: authRoles.admin
  },
  {
    path: `/teachers/AddNew`,
    component: TeacherDetails,
    auth: authRoles.admin
  },
  {
    exact: true,
    path: `/teachers/Terms/:id`,
    component: TeacherTerms,
    auth: authRoles.noMali
  },
  {
    exact: true,
    path: `/teachers/Terms/exams/:id`,
    component: TeacherExams,
    auth: authRoles.noMali
  },
  {
    exact: true,
    path: `/teachers/Terms/exams/AddNew/:id`,
    component: TeacherNewExams,
    auth: authRoles.noMali
  },
  {
    path: `/teachers/Terms/forum/:id`,
    component: TeacherForum,
    auth: authRoles.noMali
  },
  {
    exact: true,
    path: `/teachers/Terms/exercises/:id`,
    component: TeacherExercises,
    auth: authRoles.noMali
  },
  {
    path: `/teachers/Terms/exercises/:id/Edit/:eId`,
    component: ExercisesEdit,
    auth: authRoles.noMali
  },
  {
    path: `/teachers/Terms/exercises/:id/AddNew`,
    component: ExercisesEdit,
    auth: authRoles.noMali
  },
  {
    path: `/teachers/Terms/exercises/:id/userList/:eId`,
    component: UserList,
    auth: authRoles.noMali
  }
];

export default teacherRoutes;
