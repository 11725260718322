import React, { Fragment } from "react";
import { Icon } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import ReplayIcon from '@material-ui/icons/Replay';
import Tooltip from "@material-ui/core/Tooltip";
import {isMobile} from "../../utils";

const Breadcrumb = ({ routeSegments, back=false, home= true }) => {
  return (
    <div className="flex flex-middle position-relative">
      {/*{routeSegments ? (*/}
      {/*  <Fragment>*/}
      {/*    <h4 className="m-0 pb-2 font-size-16 capitalize text-middle">*/}
      {/*      {routeSegments[routeSegments.length - 1]["name"]}*/}
      {/*    </h4>*/}
      {/*    <h4 className="m-0 pb-2 ml-8 text-hint">|</h4>*/}
      {/*  </Fragment>*/}
      {/*) : null}*/}
      {home &&
      <NavLink to="/">
          <Tooltip className='mb-2' title="بازگشت به صفحه اصلی">
              <IconButton>
                  <Icon className="text-middle" color="primary">
                      home
                  </Icon>
              </IconButton>
          </Tooltip>
      </NavLink>
      }
      {routeSegments && !isMobile()
        ? routeSegments.map((route, index) => (
            <Fragment key={index}>
              <Icon className="text-hint">navigate_before</Icon>
              {index !== routeSegments.length - 1 ? (route.back) ? (
                    <span onClick={()=> window.history.go(route.back*-1)} className="capitalize text-muted cursor-pointer">{route.name}</span>
                  )
                : (
                <NavLink to={route.path}>
                  <span className="capitalize text-muted">{route.name}</span>
                </NavLink>
              ) : (
                <span className="capitalize text-muted">{route.name}</span>
              )}
            </Fragment>
          ))
        : null}
      {back &&
        <Tooltip title="بازگشت به صفحه قبل">
          <IconButton onClick={()=> window.history.go(-1)}>
            <ReplayIcon/>
          </IconButton>
        </Tooltip>
      }
    </div>
  );
};

export default Breadcrumb;
