import {AddSearchItem} from "../actions/SearchItemsActions";

const initialState = {};

const SearchItemsReducer = function(state = initialState, action) {
    switch (action.type) {
        case (AddSearchItem):{
            return {
                ...state,
                [action.name]: action.payload
            }
        }
        default: {
            return state;
        }
    }
};

export default SearchItemsReducer;
