import {
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOGIN_LOADING,
  RESET_PASSWORD, CodeHandle
} from "../actions/LoginActions";

const initialState = {
  success: false,
  loading: false,
  error: '',
  forgetPage:1,
  phoneNumber:'',
  code:''
};

const LoginReducer = function(state = initialState, action) {
  switch (action.type) {
    case LOGIN_LOADING: {
      return {
        ...state,
        loading: true,
        error: ''
      };
    }
    case LOGIN_SUCCESS: {
      return {
        ...state,
        success: true,
        loading: false
      };
    }
    case RESET_PASSWORD: {
      return {
        ...state,
        forgetPage: state.forgetPage+1,
        phoneNumber: action.payload
      };
    }
    case LOGIN_ERROR: {
      return {
        success: false,
        loading: false,
        error: action.data
      };
    }
    case CodeHandle: {
      return {
        ...state,
        code: action.payload,
        forgetPage: state.forgetPage+1
      }
    }
    default: {
      return state;
    }
  }
};

export default LoginReducer;
