import {authRoles} from "../../auth/authRoles";
import {MatxLoadable} from "../../../matx";

const LiveRoomTest = MatxLoadable({
    loader: () => import("./LiveRoomTest")
});

const LiveRoomTestAdd = MatxLoadable({
    loader: () => import("./AddEdit/LiveRoomTest")
});

const LiveRoomTestRoutes = [
    {
        exact: true,
        path: `/liveRoomTest`,
        component: LiveRoomTest,
        auth: authRoles.adminTwoFour
    },{
        exact: true,
        path: '/liveRoomTest/AddNew',
        component: LiveRoomTestAdd,
        auth: authRoles.adminTwoFour
    }
];

export default LiveRoomTestRoutes;
