import {authRoles} from "../../../auth/authRoles";
import { MatxLoadable } from "matx";

const Lesson = MatxLoadable({
    loader: () => import("./Lesson")
});
const LessonTags = MatxLoadable({
    loader: () => import("./LessonTags")
});
const Term = MatxLoadable({
    loader: () => import("./Terms")
});
const Tag = MatxLoadable({
    loader: () => import("./Tags")
});
const TagFiles = MatxLoadable({
    loader: () => import("./TagFiles")
});
const TermFiles = MatxLoadable({
    loader: () => import("./TermFiles")
});
const AddUserToTerm = MatxLoadable({
    loader: () => import("./AddUserToTerm")
});

const AddNewRoutes = [
    //lessons add and edit
    {
        path: `/lessons/AddNew`,
        component: Lesson,
        auth: authRoles.coursesWithout
    },
    {
        path: `/lessons/edit/:id`,
        component: Lesson,
        auth: authRoles.coursesWithout
    },
    //lesson tags add and edit and Add User
    {
        path: `/lessonTags/AddNew/:lId`,
        component: LessonTags,
        auth: authRoles.coursesWithout
    },
    {
        path: `/lessonTags/edit/:lId/:id`,
        component: LessonTags,
        auth: authRoles.coursesWithout
    },
    {
        exact: false,
        path: `/terms/addUser/:id`,
        component: AddUserToTerm,
        auth: authRoles.courses
    },
    //terms add and edit
    {
        path: `/terms/AddNew`,
        component: Term,
        auth: authRoles.courses
    },{
        path: `/terms/edit/:id`,
        component: Term,
        auth: authRoles.courses
    },
    //term tags add and edit
    {
        exact:true,
        path: `/tags/AddNew/:lId/:tId`,
        component: Tag,
        auth: authRoles.courses
    },{
        exact:true,
        path: `/tags/edit/:lId/:tId/:id`,
        component: Tag,
        auth: authRoles.courses
    },
    //tag videos add and edit
    {
        path: `/tags/files/AddNew/:id`,
        component: TagFiles,
        auth: authRoles.courses
    },{
        path: `/term/tags/files/edit/:id/:fId`,
        component: TagFiles,
        auth: authRoles.courses
    },
    //term files add and edit
    {
        path: `/terms/files/AddNew/:id`,
        component: TermFiles,
        auth: authRoles.courses
    },{
        path: `/terms/filesTest/AddNew/:id`,
        component: TermFiles,
        auth: authRoles.courses
    },{
        path: `/terms/files/edit/:id/:TId`,
        component: TermFiles,
        auth: authRoles.courses
    },
];

export default AddNewRoutes;
